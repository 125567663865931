import React, { useState, useEffect } from 'react';
import { updateEntryField } from '../../adapters/flowentry/flowentry-adapter';
import { RewardsManager } from '../../helpers/RewardsManager';
import FlowRewards from '../rewards/FlowRewards';
import BigPictureCounter from '../BigPictureCounter';
import { Rewards } from '../../Constants';
import '../css/post-flow-experience.css';

function PostFlowExperience(props) {
    const [showBigPictureCounter, setShowBigPictureCounter] = useState(false);
    const [rewards, setRewards] = useState(null);
    const [quantitativeRewards, setQuantitativeRewards] = useState(null);

    const calculatePostFlowRewards = (flows, isUserPostWorkFlow) => {
        const rewardsManager = new RewardsManager();
        const today = new Date();
        let rewards = {};
        
        const quantitativeRewards = rewardsManager.getEligibleQuantityBasedRewards(flows, today);
        setQuantitativeRewards(quantitativeRewards);
        if (quantitativeRewards.length > 0) {
            rewards.Quantitative = [];
            quantitativeRewards.forEach(rewardKey => {
                rewards.Quantitative.push(Rewards[rewardKey]);
            })
        }      
        // check for qualitative rewards only after work flow
        if (isUserPostWorkFlow) {
            const lastFlow = flows[flows.length - 1]; // post flow so there should be atleast 1 item.
            if (lastFlow.qualitativeRewards.length > 0) {
                rewards.Qualitative = [];
                lastFlow.qualitativeRewards.forEach(rewardKey => {
                    rewards.Qualitative.push(Rewards[rewardKey]);
                })
            }
        }
        return (rewards.Quantitative || rewards.Qualitative) ? rewards : null;
    }

    useEffect(() => {
        if (!props.flows) {
            return;
        }
        if (!quantitativeRewards || quantitativeRewards.length === 0) {
            return;
        }
        
        // else save these to the last flow
        const lastFlow = props.flows[props.flows.length - 1];
        const reqParams = {
            collectionName: lastFlow.userId,
            entryId: lastFlow._id,
        }
        const reqBody = {
            fieldName: "quantitativeRewards",
            value: quantitativeRewards
        }
        updateEntryField(reqParams, reqBody).then(result => {
            //console.log(`should have updated ${lastFlow._id}`);
        });
    }, [quantitativeRewards, props.flows])

    useEffect(() => {
        if (!props.flows) {
            return;
        }
        // calculate if any rewards due.
        const rewards = calculatePostFlowRewards(props.flows, props.isUserPostWorkFlow);
        if (rewards) {
            setRewards(rewards);
        }
        else {
            exitRewards();
        }
    }, [props.flows, props.numFlowsToBigPicture])

    const exitRewards = () => {
        // if big picture counter is eligible to be shown
        if (props.numFlowsToBigPicture > -1 && props.isUserPostWorkFlow) {
            setShowBigPictureCounter(true);
        }
        else {
            exitPostFlowExperience();
        }
    }

    const exitPostFlowExperience = () => {
        props.exit();
    }

    return (
        <div className='post-flow-experience-div'>
            {rewards && !showBigPictureCounter && 
                <FlowRewards 
                    timeOfDay={props.timeOfDay}
                    rewards={rewards}
                    exit={exitRewards}
                />
            }
            {showBigPictureCounter &&
                <BigPictureCounter
                    numFlowsRemaining={props.numFlowsToBigPicture}
                    month={props.currentMonth}
                    exit={exitPostFlowExperience}
                />        
            }
        </div>
    )
}

export default PostFlowExperience;