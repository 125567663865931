import { RewardsPriorityOrder } from "../Constants";

/* 
This is supposed to return an array of screens, where 1 screen = The array of rewards that belong on that screen.
*/
export function getRewardScreensOrder(rewards) {
    let screens = [];
    if (rewards.Qualitative) {
        if (rewards.Qualitative.length === 1) {
            // Single reward in the list returned as 1 screen
            screens.push(rewards.Qualitative);
        }
        else {
            // Show 1 reward per screen and see if that's a better experience.
            rewards.Qualitative.forEach(reward => {
                if (screens.length > 0) {
                    screens = getNewScreenPlacement(reward, screens);
                }
                else {
                    const screen = [reward]; // Create the first screen with the array of rewards to be shown on it
                    screens.push(screen);
                }
            });
            // For the time being assume if there are multiple qual rewards they'll be on 1 screen.
            // screens.push(rewards.Qualitative);
        }
    }
    if (rewards.Quantitative) {
        // Compare with the priority of elements already in the screens list to determine where to add. 
        rewards.Quantitative.forEach(reward => {
            if (screens.length > 0) {
                screens = getNewScreenPlacement(reward, screens);
            }
            else {
                const screen = [reward]; // Create the first screen with the array of rewards to be shown on it
                screens.push(screen);
            }
        });
    }

    return screens;
}

function getNewScreenPlacement(reward, existingScreens) {
    let updatedScreens = [...existingScreens];
    if (existingScreens?.length < 1) {
        // An list of screens, where a screen holds the list of rewards to be displayed on it.
        updatedScreens.push([reward]);
        return updatedScreens;
    }

    const rewardOrderNum = RewardsPriorityOrder[reward.Name];
    for (let i = 0; i < existingScreens.length; i++) {
        const currentOrderNum = getScreenPriority(existingScreens[i]);
        // when reward has higher priority than the current screen
        if (rewardOrderNum < currentOrderNum) {
            updatedScreens.splice(i, 0, [reward]); // insert the reward as a new screen
            return updatedScreens;
        }
    }
    updatedScreens.push([reward]);
    return updatedScreens;
}

function getScreenPriority(rewardsForScreen) {
    if (rewardsForScreen.length === 1) {
        return RewardsPriorityOrder[rewardsForScreen[0].Name];
    }
    else { 
        // The combined priority between 2+ rewards is the sum of their numbers. Aka when combined priorty lowers.
        const combinedPriority = rewardsForScreen.reduce((sum, reward) => {
            const value = RewardsPriorityOrder[reward.Name];
            return value + sum;
        }, 0);

        return combinedPriority;
    }
}