import React, { useState, useMemo, useEffect } from 'react';
import { RewardsBGColors } from '../../Colors';
import { getRewardScreensOrder } from '../../helpers/RewardsHelper';
import { CSSTransition } from 'react-transition-group';
import '../css/rewards.css';
import consistent from '../../images/rewards/ConsistencyReward.svg';
import contextMaster from '../../images/rewards/ContextMaster.svg';
import emotionalHonesty from '../../images/rewards/EmotionalHonesty.svg';
import sweetSavorer from '../../images/rewards/SweetSavorer.svg';
import dedicated from '../../images/rewards/Dedicated.svg';
import habitHero from '../../images/rewards/HabitHero.svg';
import DualRewardsScreen from './DualRewardsScreen';
import SingleRewardScreen from './SingleRewardScreen';

function FlowRewards(props) {
    const [rewards] = useState(props.rewards);
    const [showRewardsContainer, setShowRewardsContainer] = useState(false);
    const [displayedScreenIndex, setDisplayedScreenIndex] = useState(0);
    const [showRewardImage, setShowRewardImage] = useState(false);
    
    const style = {
        background: RewardsBGColors[props.timeOfDay]
    }

    const imageMapping = {
        'Consistent': consistent,
        'Dedicated': dedicated,
        'Habit Hero': habitHero,
        'Context Master': contextMaster,
        'Sweet Savorer': sweetSavorer,
        'Emotional Honesty': emotionalHonesty
    }

    useEffect(() => {
        setShowRewardsContainer(true);
    }, [])

    //#region JSX construction helpers

    const getRewardScreenJSX = (rewardsForScreen, buttonJSX) => {
        if (rewardsForScreen.length > 1) {
            const dualScreenProps = {
                showRewardImage: showRewardImage,
                rewards: rewardsForScreen,
                imageMapping: imageMapping,
                buttonJSX: buttonJSX
            };
            console.log('dual');
            return <DualRewardsScreen {...dualScreenProps}/>;
        }
        else {
            const singleScreenProps = {
                reward: rewardsForScreen[0],
                imageMapping: imageMapping,
                buttonJSX: buttonJSX
            }
            return <SingleRewardScreen {...singleScreenProps}/>
        }
    }

    /**
     * This is where we need to construct the jsx for the rewards screens that need to be shown. 
     * The elements to construct are:
     * 1) The button that exits.
     * 2) The button that transitions to next screen
     * 3) The CSSAnimation with transition properties for just transtion out anim
     * 4) The CSSAnimation with transition properties for transition in and out anim
     * 5) The CSSAnimation with transition properties for just transition in anim
     * 6) The closing CSSAnimation tag
     */
    const getRewardScreensJSX = (rewards) => {
        const rewardScreens = getRewardScreensOrder(rewards);
        const rewardScreensJSX = [];

        const exitRewardsButtonJSX = <button className='reward-button' 
            onClick={() => setShowRewardsContainer(false)}>
            Claim
        </button>

        const transitionToNextScreenButtonJSX = <button className='reward-button' 
            onClick={() => setDisplayedScreenIndex(-1)}>
            Claim
        </button>

        for (let i = 0; i < rewardScreens.length; i++) {
            let jsx = <></>;
            const rewardsForScreen = rewardScreens[i];

            if (i === 0) {
                const buttonJSX = rewardScreens.length > 1 ? transitionToNextScreenButtonJSX : exitRewardsButtonJSX
                const rewardScreenJSX = getRewardScreenJSX(rewardsForScreen, buttonJSX);
                const transitionProps = getTransitionExitOnlyProps(i);
                jsx = <CSSTransition {...transitionProps}>
                    <div className='rewards-container-inner'>
                        {rewardScreenJSX}
                    </div>
                </CSSTransition>
            }
            else if (i > 0 && i < rewardScreens.length - 1) {
                const rewardScreenJSX = getRewardScreenJSX(rewardsForScreen, transitionToNextScreenButtonJSX);
                const transitionProps = getTransitionInAndOutProps(i);
                jsx = <CSSTransition {...transitionProps}>
                    <div className='rewards-container-inner'>
                        {rewardScreenJSX}
                    </div>
                </CSSTransition>
            }
            else {
                const rewardScreenJSX = getRewardScreenJSX(rewardsForScreen, exitRewardsButtonJSX);
                const transitionProps = getTransitionEnterOnlyProps(i);
                jsx = <CSSTransition {...transitionProps}>
                    <div className='rewards-container-inner'>
                        {rewardScreenJSX}
                    </div>
                </CSSTransition>
            }
            rewardScreensJSX.push(jsx);
        }

        return rewardScreensJSX;
    }

    const getTransitionExitOnlyProps = (index) => {
        return {
            key: index,
            in: displayedScreenIndex === index,
            enter: false, // So enter animation classes won't apply
            timeout: 300,
            classNames: "pivot",
            onExited: (node) => {setDisplayedScreenIndex(index + 1);}
        };
    }
    
    const getTransitionEnterOnlyProps = (index) => {
        return {
            key: index,
            in: displayedScreenIndex === index,
            timeout: 500,
            classNames: "pivot",
            exit: false, // So exit animation classes won't apply
            unmountOnExit: true, // This keeps the element from showing up in the dom until in is true
            /* The enter handlers below are a hack to make sure the rewards outer container doesn't do a glitchy sideways move 
            due to scroll bar appearing when the enter pivot animation is applied.
            */
            onEnter: (node) => {document.body.style.overflow = "hidden"},
            onEntered: (node) => {document.body.style.overflow = "visible"},
        };
    }
    
    const getTransitionInAndOutProps = (index) => {
        return {
            key: index,
            in: displayedScreenIndex === index,
            timeout: {enter: 500, exit: 500},
            classNames: "pivot", // apply both enter and exit anim classes
            onExited: (node) => {setDisplayedScreenIndex(index + 1);},
            unmountOnExit: true,
            /* The enter handlers below are a hack to make sure the rewards outer container doesn't do a glitchy sideways move 
            due to scroll bar appearing when the enter pivot animation is applied.
            */
            onEnter: (node) => {document.body.style.overflow = "hidden"},
            onEntered: (node) => {document.body.style.overflow = "visible"},
        }
    }

    //#endregion
    
    const screensJSX = useMemo(() => 
        getRewardScreensJSX(rewards)
    , [displayedScreenIndex, showRewardsContainer, showRewardImage]);

    const handleExit = () => {
        // TODO: Do we want to store the Quantitative reward somewhere before exiting?
        props.exit();
    }

    return (
        screensJSX &&
        <CSSTransition
            in={showRewardsContainer}
            timeout={{enter: 1000, exit: 1000}}
            classNames="container-outer"
            onEntered={() => setShowRewardImage(true)}
            onExited={handleExit}
            unmountOnExit
        >
            <div className='rewards-container-outer' style={style}>
                {screensJSX}
            </div>
        </CSSTransition>
    )
}

export default FlowRewards;