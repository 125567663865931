import { BigPictureCutoffs, JSNumberToMonthMapping, MonthShorthandToNumberMapping } from "../Constants";
import { getDateAndColorForEntries, getDateFromMongoId } from "./EntriesHelper";
import { FlowEntryColorPalette } from "../Colors";

export const getWeekStartDate = (date) => {
  const currentDayNum = date.getDay();
  const weekStartDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - currentDayNum);
  return weekStartDate;
}

// Given a date, returns the 7 days in the whole week (Sun-Sat) corresponding to that date.
export const getWeek = (dateObj) => {
    const date = dateObj ?? new Date();
    const year = date.getFullYear();
    const month = date.getMonth();

    const dayOfMonth = date.getDate();
    const dayOfWeek = date.getDay(); // weekdays are mapped from 0-6 starting with Sunday
    let weekBeginning = dayOfMonth - dayOfWeek;
    let week = [];
    for (let i = 0; i < 7; i++) {
        let day = new Date(year, month, weekBeginning);
        week.push(day);
        weekBeginning++;
    }
    return week;
}

// Given a date, returns the whole month corresponding to that date.
export const getMonth = (dateObj) => {
    const date = dateObj ?? new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const totalDaysInMonth = getNumDaysInMonth(month, year);
  
    let monthDays = [];
  
    // based on the day of the week the 1st of the month falls on, calculate first week of the month
    const weekdayOnFirstOfTheMonth = new Date(year, month, 1).getDay();
    let firstWeek = [];
    let day = 1; // starting from 1 instead of 0 because this is supposed to represent the actual day as seen on calendar.
    for (let j = 0; j < 7; j++) {
      if (j < weekdayOnFirstOfTheMonth) {
        firstWeek.push('');
      } else {
        let date = new Date(year, month, day);
        firstWeek.push(date);
        day++;
      }
    }
    monthDays.push(firstWeek);
    
    /* Put rest of days left in the month into weeks. 
    Subtract 1 from day here to get the actual count, because above it was being counted from 1 for calendar representation
    instead of 0 like in programmatic counting.
    */
    let daysLeft = totalDaysInMonth - (day - 1);
    while(daysLeft > 0) {
        // create week from the next 7 days or less
      let week = [];
      while (week.length < 7 && day <= totalDaysInMonth) {
        let date = new Date(year, month, day);
        week.push(date);
        day++
        daysLeft--
      }
      monthDays.push(week);
    }
    return monthDays;
  }

const getNumDaysInMonth = (month, year) => {
    // found logic in https://stackoverflow.com/a/315767/4357770 
    // When we pass in 0, it rolls back to the last day of the previous month (since everymonth starts with 1).
    // That's why we have to do 'month + 1' to bring it back to the current month.
    return new Date(year, month + 1, 0).getDate();
}

export const getWeekHeading = (week) => {
  if (week === null) {
      console.error('week is null');
      return;
  }

  const weekStartMonth = JSNumberToMonthMapping[week[0].getMonth()];
  const weekStartDate = week[0].getDate();
  const weekEndMonth = JSNumberToMonthMapping[week[week.length - 1].getMonth()];
  const weekEndDate = week[week.length - 1].getDate();
  if (weekStartMonth === weekEndMonth) {
      return `${weekStartMonth} ${weekStartDate} - ${weekEndDate}`;
  }
  else {
      return `${weekStartMonth} ${weekStartDate} - ${weekEndMonth} ${weekEndDate}`;
  }
}

export const getEntriesForWeek = (allEntries, weekStart) => {
  const entriesWithDates = getDateAndColorForEntries(allEntries);
  const endDate = new Date(weekStart.getFullYear(), weekStart.getMonth(), weekStart.getDate() + 7);
  const entriesForWeek = entriesWithDates.filter(item => 
    ((weekStart <= item.date) && (item.date < endDate)));

  return entriesForWeek;
}

// This takes in the raw flow entries from mongoDB as input
export const getEntriesInDay = (entries, day) => {
  const flowUIObjects = getDateAndColorForEntries(entries);
  const flowsInDay = getFlowsInDay(day, flowUIObjects);
  return flowsInDay;
}

export const getEntriesForMonth = (allEntries, monthStart) => {
  const entriesWithDates = getDateAndColorForEntries(allEntries);
  const endDate = new Date(monthStart.getFullYear(), monthStart.getMonth() + 1, 1);
  const entriesForMonth = entriesWithDates.filter(item => ((monthStart <= item.date) && (item.date < endDate)));
  return entriesForMonth;
}

export const getEntriesForYear = (allEntries, year) => {
  const entriesWithDates = getDateAndColorForEntries(allEntries);
  const startDate = new Date(year, 0, 1);
  const endDate = new Date(year + 1, 0, 1, 0, 0, 0);
  const entriesForYear = entriesWithDates.filter(item => ((startDate <= item.date) && (item.date < endDate)));
  return entriesForYear;
}

export const getWorkEntriesInYear = (allEntries, year) => {
  const entriesWithDates = getDateAndColorForEntries(allEntries);
  const startDate = new Date(year, 0, 1);
  const endDate = new Date(year + 1, 0, 1);
  const entriesForYear = entriesWithDates.filter(item => ((item.isWorkFacing) && (startDate <= item.date) && (item.date < endDate)));
  return entriesForYear;
}

export const getDateOfFirstCheckin = (allEntries) => {
  const dateOfFirstCheckin = allEntries
    ? getDateFromMongoId(allEntries[0]._id)
    : new Date(2022, 6, 1); // default July 1 2022
  return dateOfFirstCheckin;
}

export const getNumCheckinsPerMonthInAYear = (allEntries, year) => {
  let monthNumCheckinsMap = {}
  allEntries.forEach(entry => {
    const entryDate = getDateFromMongoId(entry._id);
    const entryYear = entryDate.getFullYear();
    if (entryYear === year) {
      const month = entryDate.getMonth();
      monthNumCheckinsMap[month] = (monthNumCheckinsMap[month]+1) || 1 ;
    }
  });
  return monthNumCheckinsMap;
}

export const getMonthHeading = (dateObj) => {
  const monthNum = dateObj.getMonth();
  return `${JSNumberToMonthMapping[monthNum]} ${dateObj.getFullYear()}`;
}

export const getDateFromQueryParams = (query) => {
  if (query) {
    // e.g. query is ?view=week&date=sun-aug-28-2022
    let x = query.split('-');
    let month = x[1].charAt(0).toUpperCase() + x[1].slice(1);
    let monthNum = MonthShorthandToNumberMapping[month] - 1;
    let day = x[2];
    let year = x[3];
    return new Date(year, monthNum, day);
  }
  return null;
}

export const getViewTypeFromQueryParams = (query) => {
  if (query) {
    // e.g. query is ?view=week&date=sun-aug-28-2022
    let x = query.split('=');
    let y = x[1].split('&');
    return y[0];
  }
  return null;
}

// Flows is the list of UI objects corresponding to flows here
export const getFlowsInDay = (day, flows) => {
  let flowsInADay = (flows && typeof(day) !== 'string')
      ? flows.filter(o => o.date.toDateString() === day.toDateString())
      : []
  return flowsInADay;
}

export const calculateDayColor = (day, entries) => {
  const flowEntriesInADay = getFlowsInDay(day, entries);
  
  if (flowEntriesInADay.length === 0) {
      return 'none';
  }
  else if (flowEntriesInADay.length === 1) {
      return flowEntriesInADay[0].color.BG;
  }
  else {
      let colors = [];
      flowEntriesInADay.forEach(flowEntry => {
          const color = FlowEntryColorPalette[flowEntry.color.name];
          colors.push(color);
      });

      let gradient = "linear-gradient(to right,";
      for (let i = 0; i < colors.length; i++) {
          gradient += colors[i] + ',';
      }
      gradient = gradient.slice(0, -1); // remove last comma
      gradient = gradient + ')';
      return gradient;
  }
}

export const getNumWorkFlowsInMonth = (flows, date) => {
  const monthStart = new Date(date.getFullYear(), date.getMonth(), 1);
  const flowsInMonth = getEntriesForMonth(flows, monthStart);
  const workRelatedFlows = flowsInMonth.filter(flow => (flow.isDirectlyWorkRelated === true));
  return workRelatedFlows.length;
} 