export const AppColorTheme = Object.freeze(
    {
        // blue-pink
        morning : {
            "DARK": "#6495ED",
            "LIGHT": "#E2A39E",
        },   
        // yellows
        afternoon : {
            "DARK": "#f2994a",
            "LIGHT": "#f2c94c",
        },
        // mauve
        evening : {
            "DARK": "#9796f0",
            "LIGHT": "#fbc7d4",
        },
        // indigo-greens
        night : {
            "DARK": "#585171",
            "LIGHT": "#478798",
        }
    }
)

export const AppBGColors = Object.freeze(
    {
        "morning": "linear-gradient(to bottom, #99BDDC, #F9CA9A)",
        "afternoon": "linear-gradient(to right, #ffafbd, #ffc3a0)",
        "evening": "linear-gradient(to right, #e8cbc0, #636fa4)",
        "night": "linear-gradient(to right, #360033, #0b8793)"
    }
)

export const GreetingColors = Object.freeze(
    {
        "morning": {
            background: "linear-gradient(to right bottom,cornflowerblue,#E2A39E)"
        },
        "afternoon": {
            background: "linear-gradient(to top left, #f2994a, #f2c94c)"
        },
        "evening": {
            background: "linear-gradient(to top left, #9796f0, #fbc7d4)"
        },
        "night": {
            background: "linear-gradient(to top left, #585171, #478798)"
        },   
    }
)

export const getGreetingColors = (timeOfDay) => {
    switch(timeOfDay) {
        case 'morning':
            return GreetingColors.morning;
        case 'afternoon':
            return GreetingColors.afternoon;
        case 'evening':
            return GreetingColors.evening;
        default:
            return GreetingColors.night;
    }
}

export const EntryFlowColors = Object.freeze(
    {
       "morning":  "linear-gradient(to right bottom,cornflowerblue,#E2A39E)",
       "afternoon": "linear-gradient(to right, #f2994a, #f2c94c)",
       "evening": "linear-gradient(to right, #8D93EF, #CAD0F4)",
       "night": "linear-gradient(to right, #765274, #59ACB5)"
    }
)

export const EntriesListPageBGColors = Object.freeze(
    {
        "morning":  "linear-gradient(to right bottom,cornflowerblue,#E2A39E)",
        "afternoon": "linear-gradient(to right, #f2994a, #f2c94c)",
        "evening": "linear-gradient(to right, #636fa4, #e8cbc0)",
        "night": "linear-gradient(to right, #360033, #0b8793)"
     }
)

export const RewardsBGColors = Object.freeze(
    {
        "morning":  "linear-gradient(to bottom, #588de7, #b894aa)",
        "afternoon": "linear-gradient(to top, #f2994a, #f2c94c)",
        "evening": "linear-gradient(to bottom, #588de7, #b894aa)",
        "night": "linear-gradient(to bottom, #360033, #0b8793)"
    }
)

export const FlowEntryColorPalette = Object.freeze(
    {
        "green": "#88e07b",
        "red": "#f27171",
        "lightGreen": "#b8eeb2",
        "lightRed": "#feaaab"
    }
)

export const FlowentryCardColors = Object.freeze(
    {
        "greenColorScheme": {
            "name": "green",
            "BG": "linear-gradient(to left, #88e07b, #a1dfa4)",
            "text": "rgb(99, 156, 102)",
            "lightText": "#a4e79b",
            "tag": {
                'DARK': "rgba(140, 234, 128,0.1)",
                'LIGHT': "rgba(106, 199, 99, 0.93)",
                'MEDIUM': "rgb(146, 249, 132)"
            }
        },
        "lightGreenColorScheme": {
            "name": "lightGreen",
            "BG": "linear-gradient(to right, #b8eeb2, #bfedc3)",
            "text": "#7eb975",
            "lightText": "#a4e79b",
            "tag": {
                'DARK': "rgba(140, 234, 128,0.1)",
                'LIGHT': "rgba(18, 148, 0, 0.33)",
                'MEDIUM': "#a4e79b"
            }
        },
        "redColorScheme": {
            "name": "red",
            "BG": "linear-gradient(to right, #f27171, #ecad9e)",
            "text": "rgb(237, 207, 206)",
            "lightText": "#eeb1b0",
            "tag": {
                'DARK': "rgb(240, 134, 131, 0.1)",
                'MEDIUM': "rgb(219, 125, 123)",
                'LIGHT': "#f49f9d"
            }
        },
        "lightRedColorScheme": {
            "name": "lightRed",
            "BG": "linear-gradient(to right, #fecdc1, #feaaab)",
            "text": "rgb(185, 117, 117)",
            "lightText": "#eeb1b0",
            "tag": {
                'DARK': "rgb(240, 134, 131, 0.1)",
                'LIGHT': "#f49f9d",
                'MEDIUM': "#f49f9d"
            }
        }
    }
)

export const EnergyLevelColors = Object.freeze({
    High: '#FDBB2F',
    Medium: '#66c2a5',
    Low: '#8da0cb',
    Drained: '#E8A09A'
});

export const FeelingColors = Object.freeze({
    "Awesome!": '#77DD76',
    "Good" : '#66c2a5',
    "Alright": '#a5cab0',
    "Alright pleasant": '#a5cab0',
    "Alright unpleasant": '#9D8F87',
    "Meh": '#e49b7e',
    "Not good": '#FF6663',
    "Awful": '#e54c38',
    "Not specified": '#5A5A5A',
    "Pleasant": '#45caa0',
    "Unpleasant": '#e67e76'
});

export const LocationOfCauseColors = Object.freeze({
    "Work": '#063a76c9',
    "Beyond work": '#7297c1c9',
    "Not specified": '#bababa'
})

export const ImpactColors = Object.freeze({
    Uplifting: '#66c2a5',
    Depleting: '#e8a09a',
    Neutral: 'white',
    Warning: '#bababa'
});

export const bubbleColors = {
    "Uplifting": [
        '#9467bd',
        '#e377c2',
        '#ff7f0e',
        '#2ca02c',
        '#1f77b4',
        // '#3757b2',
        '#558eb6',
        '#ab6e62',
        '#e2b41a',
        '#9e698b',
    ],
    "Depleting": [
        '#a5654e',
        '#817957',
        '#977c87',
        '#c9915d',
        '#697784',
        '#6f888c',
        '#c6a78a',
        '#BCBCBC',
        '#35393d',
    ]
}

export const NORMILI_LANDING_COLORS = Object.freeze({
    skyBlue: "#bde3e0",
    mountainRed: "#d76c58",
    roadBeige: "#EAE6B1",
    signGreen: "#34bf7c",
    signGreenDark: "#1E9864"
});

export const GRADIENT_DIRECTIONS = [
    'to right',
    'to bottom right',
    'to bottom',
    'to bottom left',
    'to left',
    'to top left',
    'to top',
    'to top right'
];

export const DARKMODE_FLOW_GRADIENTCOLORS = Object.freeze({
    'lightGreen': {
        Color1: 'rgba(146, 232, 133, 0)',
        Color2: 'rgba(161, 223, 164, 0.27)'
    },
    'green': {
        Color1: 'rgba(146, 232, 133, 0)',
        Color2: 'rgba(161, 223, 164, 0.27)'
    },
    'lightRed': {
        Color1: 'rgba(136, 119, 114, 0)',
        Color2: 'rgba(157, 90, 90, 0.63)'
    },
    'red': {
        Color1: 'rgba(136, 119, 114, 0)',
        Color2: 'rgba(155, 71, 71, 0.63)'
    }
})

export const getFlowScreenBGColor = (timeOfDay) => {
    switch (timeOfDay) {
        case "morning":
            return EntryFlowColors.morning;
        case "afternoon":
            return EntryFlowColors.afternoon;
        case "evening":
            return EntryFlowColors.evening;
        default:
            return EntryFlowColors.night;
    }
};
