import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import '../css/rewards.css';

function DualRewardsScreen({showRewardImage, rewards, imageMapping, buttonJSX}) {
    const [firstRewardDisplay, setFirstRewardDisplay] = useState(false);
    const [secondRewardDisplay, setSecondRewardDisplay] = useState(false);
    const [rewardTextVisiblity, setRewardTextVisibility] = useState(false);
    const [showButton, setShowButton] = useState(false);

    const getCombinedRewardsText = (rewards) => {
        const names = rewards.map(reward => reward.Name);
        const lastName = names.pop(); // Remove the last name for formatting
        let rewardText = '';
        if (names.length === 1) {
            rewardText = `You've earned the ${names[0]} and ${lastName} rewards.`
        }
        else {
            rewardText = `You've earned the ${names.join(', ')}, and ${lastName} rewards!`
        }
        return rewardText;
    }

    useEffect(() => {
        setFirstRewardDisplay(showRewardImage);
    }, [showRewardImage])

    return (
        <>
            <h2 className='reward-name'>Rewards</h2>
            <div className='rewards-flex-container'>
                <CSSTransition
                    in={firstRewardDisplay}
                    timeout={500}
                    classNames="pop"
                    onEntered={() => {setSecondRewardDisplay(true);}}
                >
                    <img
                        className="reward-img-medium"
                        src={imageMapping[rewards[0].Name]}
                        aria-label="An award ribbon"
                    />
                </CSSTransition>
                <CSSTransition
                    in={secondRewardDisplay}
                    timeout={500}
                    classNames="pop"
                    onEntered={() => setRewardTextVisibility(true)}
                >
                    <img
                        className="reward-img-medium"
                        src={imageMapping[rewards[1].Name]}
                        aria-label="An award ribbon"
                    />
                </CSSTransition>
                <CSSTransition
                    in={rewardTextVisiblity}
                    timeout={1500}
                    classNames="fade"
                    onEntering={() => setShowButton(true)}
                >
                    <p className='rewards-combined-description'>{getCombinedRewardsText(rewards)}</p>
                </CSSTransition>
                {showButton && buttonJSX}
            </div>
        </>
    )
}

export default DualRewardsScreen;