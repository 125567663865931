import React from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getEntries } from '../../adapters/flowentry/flowentry-adapter';
import { CheckinType, CalendarViewTypes, BigPictureCutoffs, JSNumberToMonthFullNameMapping } from '../../Constants';
import { getBackgroundColorByTimeOfDay } from '../../helpers/EntriesHelper';
import { getDateFromQueryParams, getNumWorkFlowsInMonth, getViewTypeFromQueryParams } from '../../helpers/CalendarHelper';
import { CSSTransition } from 'react-transition-group';
import WeekView from './WeekView';
import '../css/mycheckins.css';
import MonthView from './MonthView';
import YearView from './YearView';
import RadioButtonGroup from '../RadioButtonGroup';
import { updateReminderIfApplicable } from '../../helpers/RemindersHelper';
import PostFlowExperience from './PostFlowExperience';

function MyCheckins(props) {
    let location = useLocation();

    const [entries, setEntries] = useState(null);
    const [numFlowsToBigPicture, setNumFlowsToBigPicture] = useState(null);
    const [showPostFlowExperience, setShowPostFlowExperience] = useState(false);
    const [currentMonth] = useState(JSNumberToMonthFullNameMapping[new Date().getMonth()]);

    const getInitialAnchorDate = () => {
        // if there are query params passed
        if (location.search) {
            return getDateFromQueryParams(location.search);
        }
        return null;
    }
    const [anchorDate, setAnchorDate] = useState(getInitialAnchorDate());

    const getInitialView = () => {
        // if there are query params passed
        if (location.search) {
            let viewFromQuery = getViewTypeFromQueryParams(location.search)
            return viewFromQuery;
        }
        return CalendarViewTypes.Week.Abbrv;
    }
    const [view, setView] = useState(getInitialView());

    useEffect(() => {
        const userId = props.user.uid;
        const requestParams = {
            collectionName: userId,
            entryType: CheckinType.AnyCheckin
        };
        getEntries(requestParams).then((result) => {
            if (result) {
                setEntries(result.sort((a, b) => a.date - b.date)); // sorted in ascending order;
                const numWorkFlowsInMonth = getNumWorkFlowsInMonth(result, new Date());
                setNumFlowsToBigPicture(BigPictureCutoffs.Month - numWorkFlowsInMonth);
            }
        });
    }, [props.user])

    const isUserPostFlow = () => {
        /* Notice that we're using loose inequality here != instead of !==. That is because it will just check that 
        the value is not null OR undefined, but it's okay if the value is true or false. If a user is post flow, this value 
        will be either true or false. But once they're done with the post flow experience, it gets set to null.
        */
        if (location && location.state?.isUserPostWorkFlow != null) {
            return true;
        }
        return false;
    }

    useEffect(() => {
        if (!entries) {
            return;
        }
        if (isUserPostFlow()) {
            setShowPostFlowExperience(true);

            if (props.remindersOn) {
                updateReminderIfApplicable(entries, props.user.uid);
            }
        }
    }, [entries])

    useEffect(() => {
        let viewFromQuery = getViewTypeFromQueryParams(location.search);
        let date = getDateFromQueryParams(location.search);
        if (date && viewFromQuery) {
            if (viewFromQuery !== view) {
                setView(viewFromQuery);
                setAnchorDate(date);
            }
        }
    }, [location])

    const updateView = (view) => {
        setView(view);
    }

    const updateAnchorDate = (date) => {
        setAnchorDate(date);
    }

    const background = getBackgroundColorByTimeOfDay(props.timeOfDay);
    const style = {
        background: background
    };

    const exitPostFlowExperience = () => {
        if (location.state?.isUserPostWorkFlow) {
            // reset location's post flow flag
            location.state.isUserPostWorkFlow = null;
        }
        if (numFlowsToBigPicture < 6 && numFlowsToBigPicture > -3) {
            // As they get closer to unlocking the big picture and a few flows after they have, show them month view.
            updateView(CalendarViewTypes.Month.Abbrv)
        }
        setShowPostFlowExperience(false);
    }

    let viewComponent = <></>;
    if (view === CalendarViewTypes.Week.Abbrv) {
        viewComponent = <WeekView entries={entries} anchorDate={anchorDate} updateAnchor={updateAnchorDate} />
    }
    else if (view === CalendarViewTypes.Month.Abbrv) {
        viewComponent = <MonthView entries={entries} anchorDate={anchorDate} updateAnchor={updateAnchorDate} timeOfDay={props.timeOfDay} />
    }
    else {
        viewComponent = <YearView entries={entries} anchorDate={anchorDate} updateAnchor={updateAnchorDate} timeOfDay={props.timeOfDay} />
    }

    return (
        <CSSTransition
            in={true}
            timeout={500}
            classNames={'my-checkins-page'}
            appear={true}
        >
            <div className='my-checkins-page' style={style}>
                {entries && (showPostFlowExperience ? 
                    <PostFlowExperience
                        flows={entries}
                        timeOfDay={props.timeOfDay}
                        exit={exitPostFlowExperience}
                        isUserPostWorkFlow={location.state?.isUserPostWorkFlow}
                        numFlowsToBigPicture={numFlowsToBigPicture}
                        currentMonth={currentMonth}
                    />
                    : <>
                        <RadioButtonGroup values={Object.values(CalendarViewTypes).map(type => type.Abbrv)}
                            selection={view}
                            handleChange={updateView}
                        />
                        {viewComponent}
                    </>
                )}
            </div>
        </CSSTransition>
    )
}

export default MyCheckins