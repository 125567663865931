import { JSNumberToDayMapping } from "../Constants";
import { getDateFromMongoId } from "./EntriesHelper";
import { Rewards } from "../Constants";
import businessDays from 'business-days-js';
import { getWeekStartDate } from "./CalendarHelper";

const businessDaysUS = businessDays({ excludeHolidays: ["columbus day", "veterans day"] });

// This is responsible for determining which rewards are due
export class RewardsManager {
    
    getEligibleQualityBasedRewards = (answeredQuestions, isCurrentStepLast) => {
        let eligibleRewards = [];
        if (this.isEmotionalHonestyRewardDue(answeredQuestions, isCurrentStepLast)) {
            eligibleRewards.push(Rewards.EmotionalHonesty.Key);
        }
        if (this.isSweetSavorerRewardDue(answeredQuestions, isCurrentStepLast)) {
            eligibleRewards.push(Rewards.SweetSavorer.Key);
        }
        if (this.isContextMasterRewardDue(answeredQuestions, isCurrentStepLast)) {
            eligibleRewards.push(Rewards.ContextMaster.Key);
        }
        return eligibleRewards;
    }

    /**
     * We want people to only see 1 most relevant Quantity based reward at a time. So based on the highest priority,
     * HabitHero > Dedicated > Consistent.
     */
    getEligibleQuantityBasedRewards = (flows, today) => {
        let eligibleRewards = [];
        if (this.isHabitHeroRewardDue(flows, today)) {
            eligibleRewards.push(Rewards.HabitHero.Key);
            return eligibleRewards; // early return coz no need to grant Dedicated or Consistent rewards if HabitHero is true
        }
        if (this.isDedicationRewardDue(flows,today)) {
            eligibleRewards.push(Rewards.Dedicated.Key); // early return coz no need to grant Consistent if Dedicated is true
            return eligibleRewards;
        }
        if (this.isConsistencyRewardDue(flows)) {
            eligibleRewards.push(Rewards.Consistent.Key);
        }
        return eligibleRewards;
    }

    getEligibleRewards = (answeredQuestions, isCurrentStepLast, entriesInCurrentWeek) => {
        const today = new Date();
        const eligibleRewards = [];
        if (this.isOpenerRewardDue(today)) {
            eligibleRewards.push(Rewards.Opener);
        }
        if (this.isContextMasterRewardDue(answeredQuestions, isCurrentStepLast)) {
            eligibleRewards.push(Rewards.ContextMaster);
        }
        if (this.isEmotionalHonestyRewardDue(answeredQuestions, isCurrentStepLast)) {
            eligibleRewards.push(Rewards.EmotionalHonesty);
        }
        if (this.isSweetSavorerRewardDue(answeredQuestions, isCurrentStepLast)) {
            eligibleRewards.push(Rewards.SweetSavorer);
        }
        if (this.isTrooperRewardDue(today)) {
            eligibleRewards.push(Rewards.Trooper);
        }
        if (this.isConsistencyRewardDue(entriesInCurrentWeek, today)) {
            eligibleRewards.push(Rewards.Consistent);
        }
        if (this.isDedicationRewardDue(entriesInCurrentWeek,today)) {
            eligibleRewards.push(Rewards.Dedicated);
        }
        if (this.isHabitHeroRewardDue(entriesInCurrentWeek, today)) {
            eligibleRewards.push(Rewards.HabitHero);
        }
        if (this.isCloserRewardDue(today)) {
            eligibleRewards.push(Rewards.Closer);
        }

        return eligibleRewards;
    }


    // To understand if flow was truly work related for the purpose of rewards, the user has to have flowed through work_cause_exploration step.
    didUserTalkAboutWork = (answeredQuestions) => {
        const workCauseQuestion = answeredQuestions.find(element => element.category === "work_cause_exploration"); 
        if (workCauseQuestion && workCauseQuestion.answer) {
            return true;
        }
        else {
            return false;
        }
    }
    
    //#region - Quality related rewards

    isEngagedRewardDue = (isCurrentStepLastInFlow, numAnsweredQuestions) => {
        /* 
        1) determine if number questions answered greater than 5. 
        coz right now it doesn't make sense to me give engaged for a short flow.

        2) determine if all questions were answered. Right now no questions are skippable, so if you got to the last question, 
        it means you answered everything. how to see if the last answered question was the last in the flow - It will have no children.        
        */
       if (numAnsweredQuestions > 5) {
        if (isCurrentStepLastInFlow) {
            console.log("Engaged!")
        }
       }
    }

    /* 
    This reward is given when a flow is about work, and user answered all text questions asked.
    The combined word count for text questions should be atleast 60
    The user has to flow all the way to the end.
    */
    isContextMasterRewardDue = (answeredQuestions, isCurrentStepLast) => {
        if (!this.didUserTalkAboutWork(answeredQuestions)) {
            return false;
        }

        if (!isCurrentStepLast) {
            return false;
        }

        const textInputQuestions = answeredQuestions.filter(question => (question.answerFormat === "textinput_details" || question.answerFormat === "textarea"));       
        if (textInputQuestions.length === 0) {
            return false;
        }
        let totalWords = [];
        for (let i = 0; i < textInputQuestions.length; i++) {
            const answer = textInputQuestions[i].answer?.trim();
            // each text question should atleast have an answer
            if (answer?.length > 4) {
                totalWords = totalWords.concat(answer.split(/\s+/));
            }
            else {
                return false;
            }
        }
        // User should've written atleast 60 words across the text questions combined.
        if (totalWords.length > 60) {
            return true;
        }

        return false;
    }

    /*
    This reward is given when a person is being open about not feeling great about something at work.
    They flow till the end, and if text questions were asked, they answered atleast 1 of them.
    */
    isEmotionalHonestyRewardDue = (answeredQuestions, isCurrentStepLastInFlow) => {
        if (!this.didUserTalkAboutWork(answeredQuestions)) {
            return false;
        }

        if (!isCurrentStepLastInFlow) {
            return false;
        }

        const energyQuestion = answeredQuestions.find(element => element.category === "energy");
        const lowPleasantnessOptions = ["Meh", "Not good", "Awful"];
        const feelingQuestion = answeredQuestions.find(element => element.category === "feeling");
        const locationOfCause = answeredQuestions.find(element => element.category === 'location_of_cause');
        const work_inquiry = answeredQuestions.find(element => element.category === 'work_inquiry');

        const drainedCondition = energyQuestion?.answer === "Drained";
        const loweredEnergyCondition = energyQuestion?.answer === "Low" && feelingQuestion?.answer === "Alright"
            && locationOfCause?.answer === "Things at work";
        const couldBeBetterCondition = feelingQuestion?.answer === "Alright" && energyQuestion?.answer !== "Low"
            && (work_inquiry?.answer === "Could be better" || work_inquiry?.answer === "It sucks");
        const feelingCondition = lowPleasantnessOptions.includes(feelingQuestion?.answer);

        if (drainedCondition || loweredEnergyCondition || feelingCondition || couldBeBetterCondition) {
            return this.checkTextCriteria(answeredQuestions);
        }
        
        return false;
    }

    checkTextCriteria = (answeredQuestions) => {
        const textInputQuestions = answeredQuestions.filter(question => 
            (question.answerFormat === "textinput_details" || question.answerFormat === "textarea"));
        // if any text questions were asked.
        if (textInputQuestions && textInputQuestions.length > 0) {
            // If text questions were asked, atleast 1 of them should've been answered.
            if (textInputQuestions.some(element => element.answer && element.answer.length > 4)) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            // if no text questions were asked in the flow, the text criteria doesn't apply
            return true;
        }
    }

    /* 
    This reward is granted when users reflect on things being pleasant and calm at work.
    They flow till the end.
    If text questions were asked, they answered atleast 1 of them.
    */
    isSweetSavorerRewardDue = (answeredQuestions, isCurrentStepLast) => {
        if (!this.didUserTalkAboutWork(answeredQuestions)) {
            return false;
        }
        if (!isCurrentStepLast) {
            return false;
        }
        const textInputQuestions = answeredQuestions.filter(question => 
            (question.answerFormat === "textinput_details" || question.answerFormat === "textarea"));
        // If text questions were asked, the user needs to have answered at least 1 of them.
        if (textInputQuestions.length > 0) {
            if (!textInputQuestions.some(element => element.answer && element.answer.length > 4)) {
                return false;
            }
        }
        const feelingQuestion = answeredQuestions.find(element => element.category === "feeling");
        const pleasantOptions = ["Good", "Awesome!"];
        if (!feelingQuestion) {
            return false;
        }
        if (pleasantOptions.includes(feelingQuestion.answer)) {
            return true;
        }
        else if (feelingQuestion.answer === "Alright") { 
            const workInquiry = answeredQuestions.find(element => element.category === "work_inquiry");
            if (workInquiry && 
                (workInquiry.answer === "Not too shabby" || workInquiry.answer === "I'm loving it!")) {
                return true;
            }
        }
        else {
            return false;
        }
    }
    //#endregion

    //#region Quantity based rewards - as of now, these aren't work dependent.

    /*
    Granted if a user did a flow today and they also did one yesterday. E.g if a user does a flow on Tuesday and comes back on Wed, they should get a consistent badge.
    The shortest rewarded streak is 2 flows. 
    */
    isConsistencyRewardDue = (flows) => {
        if (flows.length < 2) {
            return false;
        }

        // passed flows should be sorted from oldest to most recent
        const lastFlow = flows[flows.length - 1];
        const dateOfLastFlow = getDateFromMongoId(lastFlow._id);
        const secondLastFlow = flows[flows.length - 2];
        const dateOfSecondLastFlow = getDateFromMongoId(secondLastFlow._id);
        const dayCount = businessDaysUS.countDays(dateOfSecondLastFlow, dateOfLastFlow);

        if (dayCount.totalDays === 1 || dayCount.businessDays === 1) {
            return true;
        }

        return false;
    }

    /*
    Granted if a user has 4 or more checkins in a week on distinct days. They don't have to be contiguous days.
    This reward is only given once a week.
    */
    isDedicationRewardDue = (flows, today) => {
        const weekStartDate = getWeekStartDate(today);
        const flowsInWeek = flows.filter(item => (
            (weekStartDate <= getDateFromMongoId(item._id) && (getDateFromMongoId(item._id) <= today))
        ));
        if (flowsInWeek.length < 4) {
            return false;
        }
        // if reward was already granted this week, then don't give it again
        for (let i = 0; i < flowsInWeek.length; i++) {
            const flow = flowsInWeek[i];
            if (flow.quantitativeRewards && flow.quantitativeRewards.includes("Dedicated")) {
                return false;
            }
        }
        
        const flowDays = this.getWeekDaysOfEntries(flowsInWeek);
        if (flowDays.size >= 4) {
            return true; // award can be granted.
        }

        return false;
    }
    
    /*
    Given when a user has done flows each day Mon-Fri. Only given once a week.
    */
    isHabitHeroRewardDue = (flows, today) => {
        const weekStartDate = getWeekStartDate(today);
        const flowsInWeek = flows.filter(item => (
            (weekStartDate <= getDateFromMongoId(item._id) && (getDateFromMongoId(item._id) <= today))
        ));
        if (flowsInWeek.length < 5) {
            return false;
        }
        // if reward was already granted this week, then don't give it again
        for (let i = 0; i < flowsInWeek.length; i++) {
            const flow = flowsInWeek[i];
            if (flow.quantitativeRewards && flow.quantitativeRewards.includes("HabitHero")) {
                return false;
            }
        }

        const workDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri']
        let flowDays = this.getWeekDaysOfEntries(flowsInWeek);
        for (let day of workDays) {
            if (!flowDays.has(day)) {
                return false;
            }
        }

        return true;
    }

    getWeekDaysOfEntries = (entries) => {
        const entryDays = new Set();
        entries.forEach(entry => {
            const dateOfEntry = getDateFromMongoId(entry._id);
            const dayNum = dateOfEntry.getDay();
            const weekDay = JSNumberToDayMapping[dayNum].Day;
            entryDays.add(weekDay);
        });
        return entryDays;
    }

    /* 
    Granted if a user did checkins on atleast 3 of the same weekdays as last week.
    Let the user become eligible for this on Thursday.
    */
    isCommittedRewardDue = (entriesLastWeek, entriesInCurrentWeek) => {
        if (entriesLastWeek.length < 3) {
            return false;
        }
        const daysOfEntryFromLastWeek = this.getWeekDaysOfEntries(entriesLastWeek);
        let daysOfEntryCurrentWeek = this.getWeekDaysOfEntries(entriesInCurrentWeek);
        const today = JSNumberToDayMapping[new Date().getDay()].Day;
        daysOfEntryCurrentWeek.add(today);

        if (daysOfEntryFromLastWeek.size < 3) {
            return false;
        }
        //compare days from last week and this week
        if (daysOfEntryFromLastWeek.size > daysOfEntryCurrentWeek.size) {
            return false;
        }
        // all days of last week should be covered this week too.
        for(const day of daysOfEntryFromLastWeek) {
            if (!daysOfEntryCurrentWeek.has(day)) {
                return false;
            }
        }

        return true;
    }
    //#endregion

    /* 
    Granted when a user takes a flow on the first day of their work week. Right now that day is set as Monday for all.
    Given only once a week.
    TODO: Update the work-week start to be evaluated based on the work days specified by user during onboarding.
    */
    isOpenerRewardDue = (today) => {
        if (today.getDay() === 1) {
            return true;
        }
        return false;
    }

    /* 
    Granted when a user takes a flow in the middle of their work week. Right now that day is set as Wednesday for all.
    Given only once a week.
    // Should this be given only if a user has earned the opening reward? Or independent of that?
    TODO: Update the work-week mid to be calcuated based on the work days specified by user during onboarding.
    */
    isTrooperRewardDue = (today) => {
        if (today.getDay() === 3) {
            return true;
        }
        return false;
    }

    /* 
    Granted when a user takes a flow at the end of their work week. Right now that day is set as Friday for all.
    Given only once a week.
    // Should this be given only if a user has earned the opening reward? Or independent of that?
    TODO: Update the work-week end to be calcuated based on the work days specified by user during onboarding.
    */
    isCloserRewardDue = (today) => {
        if (today.getDay() === 5) {
            return true;
        }
        return false;
    }
}