import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import '../css/rewards.css';

function SingleRewardScreen({reward, imageMapping, buttonJSX}) {
    const [imageLoaded, setImageLoaded] = useState(false);
    const [showText, setShowText] = useState(false);
    const [showButton, setShowButton] = useState(false);

    const onImageLoad = () => {
        setImageLoaded(true);
    }

    return (
        <>
            <div className='reward-heading-img-container'>
                <h2 className='reward-name'>{reward.Name}</h2>
                <CSSTransition
                    in={imageLoaded}
                    timeout={500}
                    classNames="pop"
                    onEntered={() => setShowText(true)}
                    unmountOnExit={false} // let image be in dom even if not animated
                >
                    <img loading='eager' 
                        src={imageMapping[reward.Name]}
                        onLoad={onImageLoad}
                        className="reward-img" 
                        aria-label={`An award ribbon for ${reward.Name}`}
                        style={{visibility: imageLoaded ? "visible" : "hidden"}}
                    />
                </CSSTransition>
            </div>
            <CSSTransition
                in={showText}
                timeout={1500}
                classNames="fade"
                onEntering={() => setShowButton(true)}
            >
                <p className='reward-description'>{reward.Description}</p>
            </CSSTransition>
            {showButton && buttonJSX}
        </>
    )
}

export default SingleRewardScreen;