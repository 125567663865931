export const WorkCategories = Object.freeze({
    Exploration: 'work_cause_exploration',
    People: 'work_cause_people',
    Schedule: 'work_cause_schedule',
    WorkItself: 'work_cause_currentTask',
    ProcessPolicy: 'processPolicy_scope'
});

export const WorkCauseOptions = Object.freeze({
    People: 'People',
    Schedule: 'My schedule',
    WorkItself: 'The work itself',
    ProcessPolicy: 'Processes / Policies'
});

export const AnswerToWorkCategoryMap = Object.freeze({
    'People': 'work_cause_people',
    'My schedule': 'work_cause_schedule',
    'The work itself': 'work_cause_currentTask',
    'Processes / Policies': 'processPolicy_scope' // work_cause_processPolicy is opentext format hence this category works better in charts
});

export const PleasantFeelings = ["Awesome!", "Good", "Not too shabby", "I'm loving it!", "Alright pleasant"];

export const UnpleasantFeelings = ["Meh", "Not good", "Awful", "Could be better", "It sucks", "Alright unpleasant"];
export const UnpleasantEnergy = ["Drained"];

// LOC stands for location of cause. 
// Adding as constants here doesn't feel amazing but it's less sketchy than having these options be hardcoded in several places.
export const LOC_Options_IsWorkPlayingARole = ["Yes", "No", "Not really"];
export const LOC_Options_WhatIsAffectingYou = ["Things at work", "Things beyond work", "I'm alright really", "Both", "It's kinda both..."];
export const LOC_Options_HowIsWork = ["Not too shabby", "Could be better", "I'm loving it!", "It sucks"]

export const CheckinType = Object.freeze({
    EntryFlow: 'checkin_entryflow',
    EbbFlow: 'checkin_ebbflow',
    AnyCheckin: 'checkin'
});

export const AnswerFormat = Object.freeze({
    Radio: 'radio',
    Checkbox: 'checkbox',
    CheckboxDetailed: 'checkbox_detailed',
    CheckboxAndText: 'checkbox_&_text',
    Textarea: 'textarea',
    TextInputDetails: 'textinput_details'
});

export const CalendarViewTypes = Object.freeze({
    'Week': {
        Name: 'Week',
        Abbrv: 'W'
    },
    'Month': {
        Name: 'Month',
        Abbrv: 'M'
    },
    'Year': {
        Name: 'Year',
        Abbrv: 'Y'
    }
});

export const MonthShorthandToNumberMapping = Object.freeze({
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12
});

export const DaysOfAWeek = ['Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat', 'Sun'];

export const JSNumberToDayMapping = Object.freeze({
    0: {
        Day: 'Sun',
        Abbrv: 'S'
    },
    1: {
        Day: 'Mon',
        Abbrv: 'M'
    },
    2: {
        Day: 'Tue',
        Abbrv: 'T'
    },
    3: {
        Day: 'Wed',
        Abbrv: 'W'
    },
    4: {
        Day: 'Thu',
        Abbrv: 'T'
    },
    5: {
        Day: 'Fri',
        Abbrv: 'F'
    },
    6: {
        Day: 'Sat',
        Abbrv: 'S'
    }
});

export const JSNumberToMonthMapping = Object.freeze({
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'Jun',
    6: 'Jul',
    7: 'Aug',
    8: 'Sep',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec'
});

export const JSNumberToMonthFullNameMapping = Object.freeze({
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December'
});

export const BigPictureCutoffs = Object.freeze({
    Month: 14, //Reduced cutoff from 21 to 14 for new user for now
    Year: 30
});

export const ReminderActions = Object.freeze({
    "No Update": "No Update",
    "Move to Next Day": "Move to Next Day", // this is next business day
    "Move to Next Day Flow Time": "Move to Next Day Flow Time", // next business day as well
})

// Regex source: https://www.tutorialspoint.com/regex-in-reactjs
export const STRONG_PASSWORD_REGEX = new RegExp("^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$");

export const PROMO_CODE_FAIL_MESSAGE_MAP = {
    expiredFreeTrial: "This promo code has expired. But have a free trial on us!",
    expiredDiscount: "Sorry! This promo code has expired.",
    promoNotFound: "Sorry! This promo doesn't exist.",
    promoForNewUsersOnly: "Sorry! This promo is for new users only."
}

// #region Environment Dependent Constants
export const IS_STRIPE_IN_LIVEMODE = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY.startsWith("pk_live");

export const FirebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};
// #endregion

export const TimezoneMap = Object.freeze({
    "America/New_York": "EST",
    "America/Los_Angeles": "PST",
    "America/Chicago": "CST",
    "America/Anchorage": "AKST",
    "America/Phoenix": "MST",
    "Pacific/Honolulu": "HST",
    "EST": "America/New_York",
    "PST": "America/Los_Angeles",
    "CST": "America/Chicago",
    "AKST": "America/Anchorage",
    "MST": "America/Phoenix",
    "HST": "Pacific/Honolulu",

})

export const Rewards = Object.freeze({
    'ContextMaster': {
        Key: 'ContextMaster',
        Name: 'Context Master',
        Description: `By putting your experience into words, you made the flow a richer source of insight for your future self.`,
    },
    'EmotionalHonesty': {
        Key: 'EmotionalHonesty',
        Name: 'Emotional Honesty',
        Description: `Your emotions when honestly acknowledged, can become a compass to guide you in the direction of balance.`,
    },
    'SweetSavorer': {
        Key: 'SweetSavorer',
        Name: 'Sweet Savorer',
        Description: `Cheers for savoring the good and noticing what green flags look and feel like at work!`,
    },
    'Consistent': {
        Key: 'Consistent',
        Name: 'Consistent',
        Description: `Every time you show up, you remind yourself that you matter.`,
    },
    'Dedicated': {
        Key: 'Dedicated',
        Name: 'Dedicated',
        Description: "Keep nurturing yourself with these micro moments of connection.",
    },
    'HabitHero': {
        Key: 'HabitHero',
        Name: 'Habit Hero',
        Description: "You have prioritized your mental wellbeing with a flow each day of this work week!",
    },
    'Committed': {
        Name: 'Committed',
        Description: "You tuned in on atleast 3 of the same days as last week. That's a commitment mindset!"
    },
    // The following could be associated with claim them coins reward.
    'Opener': {
        Name: 'Opener',
        Description: "You're starting your week strong by taking this mindful moment. Remember that positive actions compound!"
    },
    'Trooper': {
        Name: 'Trooper',
        Description: 'You made it over the hump.'
    },
    'Closer': {
        Name: 'Closer',
        Description: "Here's to closing your week with intentionality and care."
    }
})

// The order in which rewards should be shown. Lowest number = highest priority
export const RewardsPriorityOrder = {
    "Emotional Honesty" : 1,
    "Habit Hero": 2,
    "Dedicated": 3,
    "Sweet Savorer": 4,
    "Context Master": 5,
    "Consistent": 6,
}