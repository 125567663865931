import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import './css/big-picture-counter.css';
import DonutChart from './insights/DonutChart';
import { BigPictureCutoffs } from '../Constants';

function BigPictureCounter(props) {
    const [disappear, setDisappear] = useState(false);

    let flowWord = "Flows";
    let subHeading = "R E M A I N";

    if (props.numFlowsRemaining === 1) {
        flowWord = "Flow";
        subHeading = "TO GO";
    }

    const counterStyle = props.numFlowsRemaining > 0 ? {} : {backgroundColor: "#f0d198"};

    const exit = () => {
        setDisappear(true);
    }

    const onEnterDone = () => {
        // For most users, they will see this counter the first time after their first work flow. 
        // We won't set an automatic exit for that first time.
        if (props.numFlowsRemaining < BigPictureCutoffs.Month - 1) {
            if (props.numFlowsRemaining !== 0) {
                setTimeout(exit, 2500);
            }
            else {
                // When it is 0, aka the big picture has just been unlocked, have a longer duration.
                setTimeout(exit, 5000);
            }
        }
    }

    return (
        <CSSTransition
            in={!disappear}
            timeout={1000}
            classNames={"big-pic-counter"}
            appear={true}
            onEntered={onEnterDone}
            onExited={props.exit}
        >
            <div>
                <div className='big-pic-counter' style={counterStyle} onClick={exit}>
                    {props.numFlowsRemaining > 0 ?
                        <div>
                            <p className='num-flows-heading'>{props.numFlowsRemaining} {flowWord}</p>
                            <p className='sub-heading'>{subHeading}</p>
                            <p className='sub-text'>To unlock your {props.month}</p>
                            <p className='sub-text' style={{fontWeight : "bold"}}>Work Big Picture</p>
                        </div>
                        :
                        <div>
                            <p className='celebration-txt' style={{marginTop : "3rem"}}>You've unlocked</p>
                            <p className='celebration-heading shimmer'>Big Picture View</p>
                            <p className='celebration-txt'>for this month!</p>
                            <p className='celebration-trophy'>🏆</p>
                        </div>
                    }
                    <DonutChart numFlowsRemaining={props.numFlowsRemaining}/>
                </div>
                <div className='counter-button-div'>
                    <button className='counter-exit-button' onClick={exit}>
                        X
                    </button>
                </div>
            </div>
        </CSSTransition>
    )
}

export default BigPictureCounter;